<template>
  <div>
    <textarea
      :placeholder="placeholder"
      :value="$attrs.value"
      id="summernote"
    />
  </div>
</template>

<script>
export default {
  name: "SummernoteTextEditor",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    showMergeTagsDropdown: {
      type: Boolean,
      default: true,
    },
    customToolbar: {
      type: Array,
    },
  },
  data() {
    return {
      summernoteControl: null,
    };
  },
  methods: {
    focus() {
      this.summernoteControl.summernote("focus");
    },
    snChange(we, contents) {
      this.summernoteControl.summernote("editor.saveRange");
      this.$emit("input", contents);
    },
  },
  watch: {
    "$attrs.value": function (newVal) {
      let curVal = this.summernoteControl.summernote("code");
      if (newVal !== curVal) {
        this.summernoteControl.summernote("code", newVal);
      }
    },
  },
  mounted() {
    // Add summernote
    let summernoteCss = document.createElement("link");
    summernoteCss.setAttribute(
      "href",
      "/assets/vendor/summernote/summernote.css"
    );
    summernoteCss.setAttribute("rel", "stylesheet");
    document.head.appendChild(summernoteCss);

    let summernoteScript = document.createElement("script");
    summernoteScript.setAttribute(
      "src",
      "/assets/vendor/summernote/summernote.js"
    );
    document.head.appendChild(summernoteScript);
    summernoteScript.onload = () => {
      this.summernoteControl = window.summernote(this.snChange);
      let x = this.summernoteControl;
      this.summernoteControl.on(
        "summernote.mouseup",
        function (/*e, mouseEvent*/) {
          x.summernote("editor.setLastRange"); // Set Range where the cursor is
        }
      );

      if (this.customToolbar) x.summernote({ toolbar: this.customToolbar });
      else
        x.summernote({
          toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "italic", "underline", "clear"]],
            ["font", ["fontname", "fontsize"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["insert", ["link"]],
            ["view", ["fullscreen", "codeview"]],
          ],
        });

      this.focus();
    };
  },
};
</script>
