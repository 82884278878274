<template>
  <div
    v-if="IsAllTasksCompleted"
    class="col-6"
    style="
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #e1e8ed;
    "
  >
    <button
      v-if="isCsatSurveyAvailable && !allSurveyCompleted"
      id="send_csat_button"
      class="btn btn-primary mr-2 position-relative"
      @click="showFeedbackTab"
      :disabled="isSubscriptionReadOnly()"
    >
      <span> {{ buttonLabel }}</span>
    </button>
    <button
      id="send_deliverable_button"
      class="btn btn-primary-custom mr-2"
      @click="goToDeliverablesTab()"
      :disabled="isSubscriptionReadOnly()"
    >
      Send Deliverables
    </button>
    <h6
      class="small text-black-50"
      v-if="
        isCsatSurveyAvailable &&
        project.deliverable_type !== 'Multi' &&
        !project.is_survey_sent &&
        project.send_csat_survey
      "
    >
      <i
        class="fa fa-exclamation-triangle pt-1"
        id="csat-pending-icon"
        style="color: orange"
      ></i
      ><span> Send CSAT to complete project</span>
    </h6>
    <ActionConfirmModal
      v-if="isShowErrorForProjectComplete"
      :title="projectCompleteErrorModalProps.title"
      :text="projectCompleteErrorModalProps.text"
      :submitText="projectCompleteErrorModalProps.submitText"
      @close="isShowErrorForProjectComplete = false"
      submitClass="btn-primary"
      @submit="
        () => {
          showFeedbackTab();
          isShowErrorForProjectComplete = false;
        }
      "
    />
  </div>
</template>

<script>
import workflowMixin from "../../../mixins/workflow";
import ActionConfirmModal from "../../../components/ui/Modals/ActionConfirmModal.vue";
import { isWorkspaceProfessionalLevel } from "@/utils/helper";

export default {
  name: "CompleteTimelineActionButtons",
  mixins: [workflowMixin],
  components: {
    ActionConfirmModal,
  },
  props: {
    workflow: {},
    project: {},
    surveysFeedback: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowErrorForProjectComplete: false,
      projectCompleteErrorModalProps: {
        title: "Action Required: Send CSAT Survey",
        text: "You are attempting to complete a project without sending the required CSAT survey. Please send the CSAT survey before closing the project.",
        submitText: "Send CSAT survey",
      },
    };
  },
  computed: {
    allSurveyCompleted() {
      return (
        this.surveysFeedback.length &&
        this.surveysFeedback.every((survey) => survey.survey_completed === 1)
      );
    },
    buttonLabel() {
      if (this.isSurveySent) {
        return "Send survey reminder";
      } else {
        return "Send CSAT survey";
      }
    },
    isCsatSurveyAvailable() {
      return (
        this.project.deliverable_type !== "Multi" &&
        isWorkspaceProfessionalLevel(this.$auth.tenant.subscription)
      );
    },
    isSurveySent() {
      return this.project?.is_survey_sent;
    },
    IsAllTasksCompleted() {
      const projectCompletedStatus =
        this.getProjectCompletedStatusWithApprovalDate(this.workflow);
      return projectCompletedStatus.allTasksCompleted;
    },
  },
  methods: {
    handleCompleteClick() {
      if (!this.isSurveySent) {
        // isSurveySent is false, show Error model
        this.isShowErrorForProjectComplete = true;
      }
    },
    showFeedbackTab() {
      this.$emit("open_feedback_tab");
    },
    goToDeliverablesTab() {
      this.$emit("open_deliverables_tab");
    },
  },
};
</script>

<style scoped>
.btn-primary-custom {
  background-color: #5995b7;
  border-color: #5995b7;
  color: #fff;
}
</style>
