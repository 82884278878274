<template>
  <div class="modal" tabindex="-1" @click.self="closeOnOutsideClick && close()">
    <div
      class="modal-dialog modal-dialog-centered"
      :style="{ maxWidth: `${maxWidth}px` }"
    >
      <form @submit.prevent="$emit('submit')" class="modal-content">
        <div
          v-if="title || showCloseButton"
          class="modal-header"
          :class="{
            'spaced-between': showCloseButton || alignTitleLeft,
          }"
        >
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            v-if="showCloseButton"
            type="button"
            class="close"
            @click="close"
          >
            <span>×</span>
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <button
            v-if="showCancelButton"
            @click="close"
            type="button"
            :class="`btn ${cancelClass}`"
            :disabled="disableSubmitButton"
          >
            {{ cancelText }}
          </button>
          <button
            v-if="showSubmitButton"
            type="submit"
            :class="`btn ${submitClass}`"
            :disabled="disableSubmitButton"
          >
            {{ submitText }}
          </button>
        </div>
        <div v-if="$slots['corner-action']" class="corner-action">
          <slot name="corner-action"></slot>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showSubmitButton: {
      type: Boolean,
      default: true,
    },
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "Close",
    },
    submitText: {
      type: String,
      default: "Save",
    },
    cancelClass: {
      type: String,
      default: "btn-default",
    },
    submitClass: {
      type: String,
      default: "btn-primary",
    },
    alignTitleLeft: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background: rgba(239, 242, 244, 0.7);
}

.modal-content {
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #eff2f4;
  border-radius: 0;
  position: relative;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  padding: 1.75rem calc(1rem + 15px);
}

.modal-header.spaced-between {
  justify-content: space-between;
}

.modal-body {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.modal-footer {
  border: none;
  justify-content: center;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.close:focus {
  outline: none;
}

.corner-action {
  position: absolute;
  right: 1.5rem;
  bottom: 0.5rem;
}
</style>
