<template>
  <div>
    <div class="row clearfix">
      <div class="col">
        <div class="d-flex">
          <div>
            <button
              v-if="isDeleteButtonVisible && $auth.user.isAgent"
              type="button"
              class="btn btn-danger custom-delete-button"
              title="Delete Scope"
              @click="isConfirmingDelete = true"
              :disabled="isSubscriptionReadOnly() || disableButton"
            >
              <i class="icon-trash"></i>
            </button>
          </div>
          <div class="d-flex-col w-100" style="margin-top: 3px">
            <div class="d-flex flex-wrap font-weight-bold">
              <div class="align-left">
                <p class="title right-border">Date: {{ scope?.created_on }}</p>
              </div>
              <!-- <div class="align-left">
                <p class="title right-border">
                  Time: {{ selectedProjectScope?.time }}
                </p>
              </div> -->
              <div class="align-left mr-4">
                <p class="title">
                  By:
                  {{ scope?.created_by_name + " " + scope?.created_by_surname }}
                </p>
              </div>
              <div class="align-left" :style="{ color: 'gray' }">
                <slot name="icon"></slot>
              </div>
            </div>
            <div class="pr-lg-5 px-2">
              <div v-html="scope?.body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionConfirmModal
      v-if="isConfirmingDelete"
      @close="isConfirmingDelete = false"
      @submit="
        () => {
          $emit('delete', scope);
          isConfirmingDelete = false;
        }
      "
    />
  </div>
</template>
<script>
import ActionConfirmModal from "@/components/ui/Modals/ActionConfirmModal.vue";

export default {
  name: "ProjectScopeTemplate",
  components: { ActionConfirmModal },
  props: {
    scope: Object,
    isDeleteButtonVisible: {
      type: Boolean,
      default: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isConfirmingDelete: false,
    };
  },
};
</script>
<style scoped>
.title {
  color: #5e5e5e;
  padding: 0 6px;
  margin-bottom: 0;
  line-height: 1.2rem;
  text-wrap: nowrap;
}
.right-border {
  border-right: 1px solid #5e5e5e;
}
.custom-delete-button {
  padding: 0.1rem 0.3rem;
  height: 1.4rem;
  font-size: 0.8rem;
  margin: 0 0 0 0.5rem;
}
</style>
