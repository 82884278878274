<template>
  <div
    :class="{
      'body d-flex flex-column': true,
      disabledComponent: isProjectCancelled || isArchived,
    }"
    style="padding-top: 10px; border: none !important"
  >
    <button
      v-if="$auth.user.isAgent && !projectScopes.length"
      style="margin-bottom: 25px"
      type="button"
      class="btn btn-primary ml-auto"
      title="Add project scope"
      data-toggle="modal"
      @click="onAddScopeClick"
      :disabled="isSubscriptionReadOnly() || disableButton"
    >
      <i class="fa fa-plus"></i>
      <span class="ml-2">Add scope</span>
    </button>
    <div class="d-flex flex-column" style="width: 100%; max-width: 900px">
      <ProjectScopeTemplate
        v-for="(scope, index) in projectScopes"
        :key="index"
        :scope="scope"
        :isDeleteButtonVisible="true"
        @delete="onDeleteClick"
        style="
          padding-bottom: 32px;
          margin-bottom: 44px;
          border-bottom: 1px solid #dde4ea;
        "
        :disableButton="disableButton"
      >
        <template v-if="$auth.user.isAgent" #icon>
          <a
            href="#"
            class="mr-2 border-bottom custom-icon-color"
            data-toggle="modal"
            @click="onEditScopeClick(scope)"
            ><i class="icon-pencil icon-custom-size"></i
          ></a>
          <a
            href="#"
            v-if="scope.visible_to_client"
            class="mr-2 custom-icon-color"
            @click="toggleVisibility($event, scope)"
            ><i class="icon-eye icon-custom-size"></i
          ></a>
          <a
            href="#"
            v-else
            class="mr-2 custom-icon-color"
            @click="toggleVisibility($event, scope)"
            ><i class="fa fa-eye-slash icon-custom-size"></i
          ></a>
        </template>
      </ProjectScopeTemplate>
    </div>

    <ProjectScopeEditorModal
      v-if="isShowScopeEditorModal"
      :scope="selectedScope"
      :title="title"
      :timeline="timeline"
      :action="action"
      @close="isShowScopeEditorModal = false"
      @save="onSaveScope"
      :disableButton="disableButton"
    />
  </div>
</template>

<script>
import ProjectScopeTemplate from "./ProjectScopeTemplate.vue";
import notesFormatterMixin from "../../../mixins/notesFormatter";
import ProjectScopeEditorModal from "./ProjectScopeEditorModal.vue";
import {
  addScope,
  deleteScope,
  updateScope,
} from "../../../apis/project-scope";

export default {
  components: {
    ProjectScopeTemplate,
    ProjectScopeEditorModal,
  },
  name: "ProjectScopeTab",
  mixins: [notesFormatterMixin],
  props: {
    timeline: {
      type: Object,
      default: null,
    },
    project_id: {
      type: Number,
      default: null,
    },
    projectScopes: {
      type: Array,
      default: () => [],
    },
    project: Object,
  },
  data() {
    return {
      isShowScopeEditorModal: false,
      isHide: false,
      title: "Add scope",
      editorText: "",
      action: "add",
      disableButton: false,
    };
  },
  computed: {
    isProjectCancelled() {
      return Boolean(this.project.cancelled);
    },
    isArchived() {
      return Boolean(this.project.archived);
    },
  },
  methods: {
    async onDeleteClick(selectedScope) {
      this.disableButton = true;
      const projectId = selectedScope.project_id;
      const scopeId = selectedScope.id;

      try {
        await deleteScope(projectId, scopeId);
        this.$emit("delete", selectedScope.id);
        this.notifySuccess("Scope deleted");
      } catch (error) {
        this.notifyError(error, "Error deleting scope");
      } finally {
        this.disableButton = false;
      }
    },
    toggleVisibility(event, scope) {
      event.preventDefault();
      const selectedScope = this.projectScopes.find(
        (scopeObj) => scopeObj.id === scope.id
      );
      if (selectedScope) {
        selectedScope.visible_to_client = !selectedScope.visible_to_client;
        if (selectedScope.visible_to_client === false) {
          selectedScope.notify_client = false;
        }
        const payload = {
          body: selectedScope.body,
          stage_id: selectedScope?.stage_id,
          visible: {
            client: selectedScope.visible_to_client,
            agency: selectedScope.visible_to_agency,
          },
          notify: {
            client: selectedScope.notify_client,
            agency: selectedScope.notify_agency,
          },
        };
        updateScope(this.project_id, selectedScope.id, payload)
          .then(() => {
            this.notifySuccess("Scope visibility updated");
          })
          .catch((error) => {
            this.notifyError(error, "Error updating scope visibility");
            // Rollback the change
            selectedScope.visible_to_client = !selectedScope.visible_to_client;
          });
      }
    },
    onEditScopeClick(scopeData) {
      this.title = "Edit scope";
      this.action = "edit";
      this.selectedScope = scopeData;
      this.isShowScopeEditorModal = true;
    },
    onAddScopeClick() {
      this.editorText = "";
      this.selectedScope = undefined;
      this.title = "Add scope";
      this.action = "add";
      this.isShowScopeEditorModal = true;
    },
    async onSaveScope({ payload, action, id, project_id }) {
      this.disableButton = true;
      if (action === "edit") {
        try {
          const updatedScope = await updateScope(project_id, id, payload);
          const targetScopeIndex = this.projectScopes.findIndex(
            (scope) => scope.id === id
          );
          if (targetScopeIndex !== -1) {
            this.formatNotes(updatedScope, this.timeline);
            this.$emit("update", targetScopeIndex, updatedScope);
            this.notifySuccess("Scope updated");
          } else {
            this.notifyError("Scope not found", "Error updating scope");
          }
        } catch (error) {
          this.notifyError(error, "Error updating scope");
        } finally {
          this.disableButton = false;
        }
      } else {
        try {
          const newScope = await addScope(this.project_id, payload);
          this.formatNotes(newScope, this.timeline);
          this.$emit("add", newScope);
          this.notifySuccess("Scope added");
        } catch (error) {
          this.notifyError(error, "Error adding scope");
        } finally {
          this.disableButton = false;
        }
      }
      this.isShowScopeEditorModal = false;
    },
  },
};
</script>

<style>
.custom-icon-color {
  color: gray;
}
</style>
