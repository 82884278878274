<template>
  <div class="container">
    <div class="d-flex mb-3 justify-content-between">
      <div class="align-left">
        <label class="mt-2 mr-1 custom-label" for="ddlTemplates">Survey</label>
        <button
          id="ddlTemplates"
          class="btn btn-outline-secondary dropdown-toggle mr-2"
          type="button"
          style="min-width: 158px"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          :disabled="surveyIdToSend"
        >
          {{ selectedSurvey?.name || "Select survey" }}
        </button>
        <div class="dropdown-menu">
          <a
            v-for="(surveyTemplate, index) in surveyTemplates"
            :key="index"
            class="dropdown-item"
            @click="selectedSurveyIndex = index"
            >{{ surveyTemplate.name }}</a
          >
        </div>
        <button
          v-if="!surveyTemplates.length"
          @click="addSurvey"
          type="button"
          class="btn btn-primary custom-add-button"
          title="Add Survey"
          :disabled="isSubscriptionReadOnly()"
        >
          <span class="sr-only">Add Survey</span>
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="d-flex mb-3 justify-content-between">
      <div class="align-left">
        <label class="mt-2 mr-1 custom-label" for="ddlTemplates"
          >Recipient</label
        >
        <button
          id="ddlTemplates"
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ selectedContactLabel }}
        </button>
        <div class="dropdown-menu">
          <a
            v-for="(contact, index) in formattedContacts"
            :key="index"
            class="dropdown-item"
            @click="contactSelected(contact)"
            >{{ contact?.text }}</a
          >
        </div>
      </div>
      <div v-if="emailTemplates?.length" class="align-right">
        <label class="mt-2 mr-1" for="ddlTemplates">Email templates</label>
        <button
          id="ddlTemplates"
          class="btn btn-outline-secondary dropdown-toggle ml-2"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ emailTemplate?.title }}
        </button>
        <div class="dropdown-menu">
          <a
            v-for="(template, index) in emailTemplates"
            :key="template?.title"
            class="dropdown-item"
            @click="chosenTemplateIndex = index"
            >{{ template?.title }}</a
          >
        </div>
      </div>
    </div>
    <div class="form-group">
      <summernote-text-editor
        v-model="emailText"
        :placeholder="placeholder"
        ref="summernoteTextEditor"
      />
    </div>
    <div class="align-right d-flex justify-content-end">
      <div class="btn-group float-right mb-4" role="group">
        <button
          type="button"
          class="btn btn-info pr-0"
          @click="preview"
          :disabled="!this.selectedSurvey || disableButton"
        >
          Preview &amp; Send
        </button>
        <button
          type="button"
          class="btn btn-info dropdown-toggle"
          data-toggle="dropdown"
        ></button>
        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            @click="onSendButtonClick"
            :disabled="isSubscriptionReadOnly() || disableButton"
            >Send now</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SummernoteTextEditor from "../SummernoteTextEditor.vue";
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import { getClientById } from "../../../apis/clients";
import SurveryEmailPreviewModal from "./SurveyEmailPreviewModal.vue";
import { getAllSurvey, sendSurveyEmail } from "../../../apis/survey";
import eventBus, { channels } from "@/eventBus";
export default {
  name: "SurveyEmailEditor",
  components: { SummernoteTextEditor },
  mixins: [textEditorCompilerMixin],
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    surveysStatus: {
      type: Array,
      default: () => [],
    },
    surveyIdToSend: {
      type: Number || undefined,
    },
  },
  data() {
    return {
      emailTemplates: [],
      surveyTemplates: [],
      revisionIdForReminder: "",
      emailText: "",
      updateStatus: true,
      placeholder: "Add text here",
      chosenTemplateIndex: 0,
      selectedSurveyIndex: 0,
      client: {},
      contacts: [{}],
      selectedContact: {},
      isReviewSubmitted: false,
      disableButton: false,
    };
  },
  watch: {
    chosenTemplateIndex: function (new_val) {
      let newSelectedTemplateBody = this.emailTemplates?.[new_val]?.body;
      newSelectedTemplateBody = this.replaceContactPlaceholder(
        this.selectedContact,
        newSelectedTemplateBody
      );
      this.emailText = this.replacePlaceholdersHelper(newSelectedTemplateBody);
    },
    async project(newProject, oldProject) {
      if (newProject && newProject.client_id !== oldProject?.client_id) {
        this.contacts = newProject.contacts;
        this.client = await getClientById(newProject.client_id);
      }
    },
    "$store.getters.emailTemplates": {
      handler(newTemplates) {
        this.emailTemplates = newTemplates
          .filter((template) => template.type === "survey")
          .sort((a, b) => a.id - b.id);

        if (this.emailTemplates.length > 0) {
          this.emailText = this.replacePlaceholdersHelper(
            this.emailTemplates[0].body
          );
        }
      },
      immediate: true,
    },
  },
  computed: {
    selectedSurvey() {
      if (this.surveyIdToSend) {
        return this.surveyTemplates.find(
          (template) => template.id === this.surveyIdToSend
        );
      }
      return this.surveyTemplates?.[this.selectedSurveyIndex];
    },
    emailTemplate() {
      return this.emailTemplates[this.chosenTemplateIndex ?? 0];
    },
    formattedContacts() {
      return this.contacts
        ?.map((contact) => ({
          text: `${contact.firstname} ${contact.lastname ?? ""}`.trim(),
          value: contact.id,
          $isDisabled: contact.$isDisabled === true,
        }))
        .sort((a, b) => b.value - a.value); // Sort in descending order based on value
    },
    selectedContactLabel() {
      return this.selectedContact?.value
        ? `${this.selectedContact.text}`
        : "Existing contacts";
    },
  },
  methods: {
    addSurvey() {
      this.$router.push({
        path: "/templates/survey-templates",
      });
    },
    contactSelected(selectedContact) {
      this.selectedContact = selectedContact;
      this.emailText = this.replaceContactPlaceholder(
        selectedContact,
        this.emailTemplates[this.chosenTemplateIndex]?.body
      );
    },
    preview() {
      const modalOptions = {
        name: "survery-email-preview-modal",
        height: "auto",
        width: "700px",
      };
      this.$modal.show(
        SurveryEmailPreviewModal,
        {
          client: this.client,
          selectedSurvey: this.selectedSurvey,
          template: this.emailText,
          project: this.project,
        },
        modalOptions,
        {
          "before-close": async (e) => {
            if (this.isSubscriptionReadOnly()) return;

            const newValue = e.params?.value;
            if (!newValue) return;
            this.onSendButtonClick();
          },
        }
      );
    },
    onEditorChange(value) {
      this.emailText = this.replacePlaceholdersHelper(value);
    },
    async onSendButtonClick() {
      if (this.isSubscriptionReadOnly() || this.disableButton) return;

      this.disableButton = true;
      if (!this.selectedSurvey) {
        this.notifyError("Please select or add a new survey", "Error", 5000);
        this.disableButton = false;
        return;
      }
      if (!this.selectedContact.value) {
        this.notifyError("Please Select Recipient", "Error", 5000);
        this.disableButton = false;
        return;
      }

      const projectid = this.project?.id;
      const surveyId = this.selectedSurvey.id;
      const payload = {
        email_body: this.emailText,
        contact_id: this.selectedContact.value,
      };

      try {
        let surveyStatus = this.getSurveyStatus(
          projectid,
          this.selectedContact.value,
          this.surveysStatus
        );
        if (!surveyStatus || !surveyStatus.survey_completed) {
          const emailSent = await sendSurveyEmail(projectid, surveyId, payload);
          if (emailSent) {
            surveyStatus
              ? this.notifySuccess("Survey email reminder sent")
              : this.notifySuccess("Survey email sent");
            eventBus.$emit(channels.fetchProject);
            eventBus.$emit(channels.fetchAllSurveyWithFeedback);
            eventBus.$emit(channels.fetchAllSurveyStatus);
          } else {
            this.notifyError("Error sending survey email");
          }
        } else if (surveyStatus.survey_completed) {
          this.notifyError("Survey already completed");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        this.notifyError("Error sending survey email");
      } finally {
        this.disableButton = false;
      }
    },
    replacePlaceholdersHelper(text) {
      return this.replacePlaceholders(
        text,
        this.root,
        this.client,
        undefined,
        this.project,
        false
      );
    },
    replaceContactPlaceholder(selectedContact, body) {
      if (Object.keys(selectedContact).length !== 0) {
        body = body?.replace(
          /\[recipient fname\]/g,
          selectedContact.text?.split(" ")[0]
        );
      }
      return body;
    },
    onToggleChange(new_value) {
      this.updateStatus = new_value;
    },
    getSurveyStatus(projectId, contactId, surveysStatus) {
      return surveysStatus.find(
        (surveyStatus) =>
          surveyStatus.project_id === projectId &&
          surveyStatus.contact_id === contactId
      );
    },
  },
  async mounted() {
    try {
      this.surveyTemplates = (await getAllSurvey()) || [];
      this.contacts = this.project?.contacts;
    } catch (error) {
      console.error("Error:", error);
    }
  },
};
</script>
<style scoped>
.custom-label {
  min-width: 70px;
}
.custom-add-button {
  padding: 0.1rem 0.3rem;
  height: 1.4rem;
  font-size: 0.8rem;
  margin: 0 0 0 0.5rem;
}
</style>
