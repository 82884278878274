<template>
  <Modal
    :title="title"
    :show-close-button="false"
    :maxWidth="650"
    :alignTitleLeft="true"
    @submit="onSaveNote"
    @close="$emit('close')"
    cancel-text="Close"
    submit-text="Save"
    :disableSubmitButton="disableButton"
  >
    <div class="container">
      <div class="d-flex-col">
        <div class="row clearfix">
          <div class="col-md-6 col-5">
            <div>
              <label for="stage-list"><b>Attach note to stage</b></label>
              <div class="input-group mb-3">
                <button
                  id="ddlTemplates"
                  class="btn btn-outline-secondary dropdown-toggle p-1 ml-1"
                  type="button"
                  data-toggle="dropdown"
                >
                  {{ currentSelectedStage?.title || "Select stage" }}
                </button>
                <div class="dropdown-menu">
                  <a
                    v-for="(stage, index) in timeline?.tasks"
                    :key="index"
                    class="dropdown-item"
                    @click="onStageSelected(stage)"
                    >{{ stage.title }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-7">
            <table>
              <tr>
                <th></th>
                <th>Notify</th>
                <th>Visible</th>
              </tr>
              <tr>
                <td>Client</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isClientNotify"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isClientVisible"
                      :disabled="isClientVisibleDisabled"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Internal team</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isInternalTeamNotify"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isInternalTeamVisible"
                      :disabled="isInternalTeamVisibleDisabled"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="form-group">
          <summernote-text-editor
            v-model="editorText"
            ref="summernoteTextEditor"
            :placeholder="placeholder"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import SummernoteTextEditor from "../SummernoteTextEditor.vue";
export default {
  components: {
    Modal,
    SummernoteTextEditor,
  },
  name: "NotesEditorModal",
  props: {
    notesData: {
      type: Object,
      default: undefined,
    },
    timeline: {
      type: Object,
      default: null,
    },
    selectedStage: {
      type: Object,
      default: () => {},
    },
    title: String,
    action: String,
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chosenNotesIndex: 0,
      placeholder: "Add text here",
      isInternalTeamNotify: this.notesData?.notify_agency || false,
      isInternalTeamVisible: this.notesData?.visible_to_agency || false,
      isClientNotify: this.notesData?.notify_client || false,
      isClientVisible: this.notesData?.visible_to_client || false,
      editorText: this.notesData?.body || "",
      currentSelectedStage: this.selectedStage || {},
    };
  },
  computed: {
    isClientVisibleDisabled() {
      return this.isClientNotify;
    },
    isInternalTeamVisibleDisabled() {
      return this.isInternalTeamNotify;
    },
  },
  methods: {
    onStageSelected(stage) {
      this.currentSelectedStage = stage;
    },
    onEditorChange(noteText) {
      this.editorText = noteText;
    },
    onSaveNote() {
      const imgTagRegex = /<img[^>]+>/g;
      // Replace <img> tags with modified version
      const modifiedEditorText = this.editorText.replace(
        imgTagRegex,
        (imgTag) => {
          // Add style attribute with width: 100% to <img> tag
          const modifiedImgTag = imgTag.replace(
            /<img /,
            '<img style="max-width: 100%;" '
          );
          return modifiedImgTag;
        }
      );
      const payload = {
        body: modifiedEditorText,
        timeline_id: this.timeline?.id,
        stage_id: this.currentSelectedStage?.id,
        visible: {
          client: this.isClientVisible,
          agency: this.isInternalTeamVisible,
        },
        notify: {
          client: this.isClientNotify,
          agency: this.isInternalTeamNotify,
        },
      };
      if (!this.currentSelectedStage?.id) {
        this.notifyError("Please Select Stage");
      } else {
        this.$emit("save", {
          payload,
          action: this.action,
          id: this.notesData?.id,
          project_id: this.notesData?.project_id,
        });
      }
    },
  },
  watch: {
    isClientNotify() {
      if (this.isClientNotify === true) {
        this.isClientVisible = true;
      }
    },
    isInternalTeamNotify() {
      if (this.isInternalTeamNotify === true) {
        this.isInternalTeamVisible = true;
      }
    },
  },
};
</script>
<style>
.custom-icon-color {
  color: gray;
}
table {
  width: 100%;
}
th,
td {
  padding: 2px;
  text-align: left;
}
.container {
  color: #5d5d5d;
  font-family: Montserrat;
}
</style>
