<template>
  <wrapper-page>
    <!-- <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-right hidden-xs">
        <button
          v-on:click="submitForm"
          class="btn btn-primary"
          :disabled="isSubscriptionReadOnly()"
        >
          {{ isCreating ? "Create" : "Update" }} Client
        </button>
      </div>
    </template> -->

    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <ul class="nav nav-tabs" v-if="!isCreating">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#clientDetails"
                  :class="activeTab == 'clientDetails' ? `active` : ``"
                  @click="activeTab = 'clientDetails'"
                  >Client Details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#active_projects"
                  :class="activeTab == 'active_projects' ? `active` : ``"
                  @click="activeTab = 'active_projects'"
                  >Active Projects</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#archived_projects"
                  :class="activeTab == 'archived_projects' ? `active` : ``"
                  @click="activeTab = 'archived_projects'"
                  >Archived Projects</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link d-flex gap-x-2"
                  data-toggle="tab"
                  href="#clientAnalytics"
                  :class="activeTab == 'clientAnalytics' ? `active` : ``"
                  @click="activeTab = 'clientAnalytics'"
                >
                  <span>Client Analytics</span>
                  <i
                    v-if="!isClientAnalyticsAvailable"
                    class="fa fa-lock"
                    style="margin-top: 3px"
                  ></i>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane"
                id="clientDetails"
                :class="activeTab == 'clientDetails' ? `active` : ``"
              >
                <ClientDetails
                  :client="client"
                  :projectsSurveysAverageRating="projectsSurveysAverageRating"
                  :projects="clientProjects"
                  :allProjectRating="allProjectRating"
                  :contacts="contacts"
                  :isLoadingDetails="isLoadingDetails"
                />
              </div>
              <div
                v-if="activeTab === 'active_projects'"
                class="tab-pane"
                id="active_projects"
                :class="activeTab == 'active_projects' ? `active` : ``"
              >
                <div
                  class="row clearfix"
                  style="background: #f7f9fb !important"
                >
                  <div class="col-12">
                    <!-- Active Project list -->
                    <div class="header align-right mt-2">
                      <button
                        @click="addProject"
                        type="button"
                        class="btn btn-primary"
                        title="Add Project"
                        :disabled="isSubscriptionReadOnly()"
                      >
                        <span class="sr-only">Add Project</span>
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <!-- <client-details-projects-table
                      :projects="projectToDisplay"
                      :activeTab="activeTab"
                      v-if="!isLoadingWorkflows"
                    /> -->
                    <projects-table
                      key="active"
                      :projects="projectToDisplay"
                      :showSearchBar="false"
                      :isClientDetailsPage="true"
                      identifier="active-js-data-table"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="activeTab === 'archived_projects'"
                class="tab-pane"
                id="archived_projects"
                :class="activeTab == 'archived_projects' ? `active` : ``"
              >
                <div
                  class="row clearfix"
                  style="background: #f7f9fb !important"
                >
                  <div class="col-12 p-2">
                    <!-- Archived Project list -->
                    <!-- <client-details-projects-table
                      :projects="projectToDisplay"
                      :activeTab="activeTab"
                      v-if="!isLoadingWorkflows"
                    /> -->
                    <projects-table
                      key="archived"
                      :projects="projectToDisplay"
                      :showSearchBar="false"
                      :isClientDetailsPage="true"
                      :isArchived="true"
                      identifier="archived-js-data-table"
                    />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="clientAnalytics"
                :class="activeTab == 'clientAnalytics' ? `active` : ``"
              >
                <div class="row clearfix">
                  <div class="col-12">
                    <ClientAnalytics
                      v-if="activeTab === 'clientAnalytics'"
                      :client="client"
                      :projectsSurveysAverageRating="
                        projectsSurveysAverageRating
                      "
                      :projects="clientProjects"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>
<script>
import { getClientById, getClientProjects } from "@/apis/clients";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import ClientDetails from "./ClientDetails.vue";
import { getAllWorkflows } from "@/apis/workflows";
import { projectsSurveysAverageRating } from "../../apis/survey";
import ClientAnalytics from "./ClientAnalytics.vue";
import workflowMixin from "../../mixins/workflow";
import ProjectsTable from "../../components/ui/ProjectsTable.vue";
import { isWorkspaceStarterLevel } from "@/utils/helper";

export default {
  name: "ClientDetailsPage",
  mixins: [workflowMixin],
  components: {
    WrapperPage,
    ClientDetails,
    ClientAnalytics,
    ProjectsTable,
  },
  data() {
    return {
      activeTab: "clientDetails",
      isLoadingDetails: false,
      isLoadingWorkflows: false,
      client: {},
      contacts: [{}],
      clientId: this.$route.params.clientId,
      projectsSurveysAverageRating: [],
      allProjectRating: {
        totalAverageRating: 0,
        totalProject: 0,
      },

      projects: [],
      archivedProjects: [],
    };
  },
  async created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  async mounted() {
    try {
      if (this.isCreating) return;

      this.isLoadingDetails = true;
      await this.fetchClientDetails(this.clientId);
    } finally {
      this.isLoadingDetails = false;
    }
  },
  computed: {
    isClientAnalyticsAvailable() {
      return isWorkspaceStarterLevel(this.$auth.tenant.subscription);
    },
    clientProjects() {
      return [...this.projects, ...this.archivedProjects];
    },
    isCreating() {
      return this.$route.params.clientId === "create";
    },
    projectToDisplay() {
      if (this.activeTab === "active_projects") {
        return this.projects;
      } else {
        return this.archivedProjects;
      }
    },
  },
  methods: {
    async fetchClientDetails(clientId) {
      try {
        let client = getClientById(clientId);
        const openProjects = await getClientProjects(clientId, "open");
        const archivedProjects = await getClientProjects(clientId, "archived");

        const resetWorkflow = (projects) => {
          projects.forEach((p) => {
            p.workflow = null;
          });
        };

        resetWorkflow(openProjects);
        resetWorkflow(archivedProjects);

        await Promise.all([
          this.loadWorkflowsInProjects(openProjects),
          this.loadWorkflowsInProjects(archivedProjects),
        ]);

        this.projects = openProjects;
        this.archivedProjects = archivedProjects;
        this.client = await client;
        if (!this.client.language) this.$set(this.client, "language", "en");

        this.contacts = this.client.contacts || [];

        const allProjectsSurveysAverageRating =
          await projectsSurveysAverageRating();
        if (allProjectsSurveysAverageRating) {
          this.projectsSurveysAverageRating =
            allProjectsSurveysAverageRating.filter(
              (projectSurveysAverageRating) =>
                projectSurveysAverageRating.client_id === Number(clientId)
            );
        }
      } catch (err) {
        this.notifyError(err, "Failed to fetch client details");
      }
    },
    async loadWorkflowsInProjects(projects) {
      this.isLoadingWorkflows = true;
      try {
        // Fetch all workflows for the loaded projects
        const wfIds = projects.reduce(function (accumulator, curProject) {
          if (
            curProject.workflow_id &&
            accumulator.indexOf(curProject.workflow_id) == -1
          ) {
            accumulator.push(curProject.workflow_id);
          }
          return accumulator;
        }, []);
        if (wfIds.length > 0) {
          let workflows = await getAllWorkflows("id in (" + wfIds.join() + ")");
          projects.forEach((project) => {
            const workflow = workflows.find(
              (wf) => wf.id === project.workflow_id
            );
            project.workflow = workflow;
            project.status =
              this.getProjectCompletedStatusWithApprovalDate(workflow);
          });
        }
      } finally {
        this.isLoadingWorkflows = false;
      }
    },
    addProject() {
      this.$router.push({
        path: "/projects/create",
        query: { client: this.client?.id },
      });
    },
  },
  watch: {
    projectsSurveysAverageRating() {
      [...this.archivedProjects, ...this.projects].forEach((p) => {
        const ratingObj = this.projectsSurveysAverageRating.find((survey) => {
          return survey.project_id === p.id;
        });
        if (!ratingObj) p.isSurveySent = false;
        else {
          p.isSurveySent = true;
          if (ratingObj.rating === 0) {
            p.isFeedbackSubmitted = false;
          } else {
            p.isFeedbackSubmitted = true;
            this.allProjectRating.totalProject++;
            this.allProjectRating.totalAverageRating += ratingObj.rating;
            p.averageRating = (ratingObj.rating * 2) / 10 || 0;
          }
        }
      });
    },
  },
};
</script>

<style>
.btn-primary {
  background-color: #16a1b8;
  border-color: #16a1b8;
}
</style>
