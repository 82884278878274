<template>
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="header">
          <h2>{{ title || "Contacts" }}</h2>
        </div>
        <div class="body py-3">
          <div class="row clearfix">
            <div class="col-12 col-sm-6">
              <button
                @click="addContact"
                type="button"
                class="btn btn-primary"
                title="Add Contact"
                :disabled="isSubscriptionReadOnly() || disableButton"
              >
                <i class="fa fa-plus"></i>
                <span class="ml-2">New contact</span>
              </button>
            </div>
            <div
              v-if="withExistingContacts"
              class="mt-2 mt-sm-0 col-12 col-sm-6"
            >
              <div style="max-width: 200px" class="ml-sm-auto">
                <multi-select-dropdown
                  :field="{
                    name: 'existing-contacts',
                    placeholder: 'Existing Contacts',
                    options: contactOptions,
                  }"
                  :multiple="false"
                  @input="contactSelected"
                  :readonly="contactOptions.length === 0"
                  :closeOnSelect="false"
                  :showLabels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                ></multi-select-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="body">
          <form ref="contactsForm" @submit.prevent="submitClient">
            <div
              v-for="(contact, i) in contactArray"
              :key="`contact-${i}`"
              class="d-flex clearfix mb-2"
            >
              <div class="row flex-grow-1 d-flex">
                <div
                  v-for="field in contactFields"
                  :key="`${i}-${field.name}`"
                  class="col-lg-3 col-md-6"
                >
                  <form-input-group
                    v-if="field.name === 'email'"
                    :value="contact.email"
                    @input="contact.email = $event"
                    @blur="handleEmailInput(i, field.name, $event)"
                    :field="field"
                    :required="true"
                  />
                  <form-input-group
                    v-else
                    v-model="contact[field.name]"
                    :field="field"
                    :required="field.name == 'firstname'"
                  />
                </div>
                <div class="col-lg-3 col-md-6">
                  <b><label>Client portal access invite</label></b>
                  <div class="input-group mb-3" style="color: #77797c">
                    <a v-if="contact.inviteStatus?.last_login"
                      ><i class="icon-login mr-1"></i> Last access on
                      {{
                        toWorkspaceDateTimeFormat(
                          getLuxonDateTime(
                            contact.inviteStatus?.last_login,
                            true
                          )
                        ) +
                        " " +
                        getTime(contact.inviteStatus?.last_login)
                      }}</a
                    >
                    <template v-else-if="contact.inviteStatus?.invite_sent">
                      <a href="javascript:void(0);" @click="sendInvite(i)"
                        ><i class="fa fa-envelope text-muted mr-1"> </i>
                      </a>
                      Client invited
                    </template>
                    <a v-else href="javascript:void(0);" @click="sendInvite(i)"
                      ><i class="fa fa-envelope text-muted mr-1"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div v-if="contacts.length !== 1" class="ml-4 d-flex">
                <button
                  @click="removeContact(i)"
                  type="button"
                  class="mt-auto mb-auto btn-sm btn btn-danger"
                  title="Remove Contact"
                  :disabled="isSubscriptionReadOnly() || disableButton"
                >
                  <span class="sr-only">Remove Contact</span>
                  <i class="icon-trash"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputGroup from "./FormInputGroup.vue";
import { sendEmail } from "@/apis/app";
import { getContactById, getAllContactAccess } from "@/apis/contacts";
import MultiSelectDropdown from "@/components/ui/MultiSelectDropdown.vue";
import timeMixin from "../../mixins/time";

export default {
  name: "ContactListEditor",
  mixins: [timeMixin],
  components: { MultiSelectDropdown, FormInputGroup },
  props: {
    contacts: Array,
    contactOptions: {
      type: Array,
      default: () => [],
    },
    title: String,
    withExistingContacts: {
      type: Boolean,
      default: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contactFields: [
        { label: "First Name", name: "firstname" },
        { label: "Last Name", name: "lastname" },
        {
          label: "Email",
          name: "email",
          type: "email",
          inviteStatus: "none",
        },
      ],
      contactWithInviteStatus: this.contacts,
      allContactInviteStatus: [],
    };
  },
  computed: {
    contactArray() {
      return this.contacts.map((contactX) => {
        const foundContact = this.contactWithInviteStatus.find(
          (contactY) => contactY.email === contactX.email
        );
        if (foundContact) {
          contactX.inviteStatus = this.getInviteStatus(foundContact.email);
        }
        return contactX;
      });
    },
  },
  methods: {
    getTime(inputDateString) {
      const inputDate = new Date(inputDateString);
      const hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      // Determine AM or PM based on hours
      const ampm = hours >= 12 ? "PM" : "AM";
      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;
      // Format the time in the desired format (e.g., "hh:mm AM/PM")
      const formattedTime = `${formattedHours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;
      return formattedTime;
    },

    handleEmailInput(index, fieldName, value) {
      if (this.emailNotExist(index, value)) {
        this.$set(this.contacts[index], fieldName, value);
        this.$set(
          this.contacts[index],
          "inviteStatus",
          this.getInviteStatus(value)
        );
      } else {
        this.$set(this.contacts[index], fieldName, "");
      }
    },
    getInviteStatus(targetEmail) {
      const status = this.allContactInviteStatus.find(
        (entry) =>
          entry?.contact_email?.toLowerCase() === targetEmail?.toLowerCase()
      );
      return status;
    },
    emailNotExist(index, email) {
      const isEmailExist = this.contacts.some(
        (contact, i) => contact.email === email && i !== index
      );
      if (isEmailExist) {
        alert("Email already exists. Please enter a different email.");
        return false;
      }
      return true;
    },
    async contactSelected(selectedContact) {
      if (
        !selectedContact ||
        !selectedContact.length ||
        !selectedContact[0].value
      )
        return;

      this.$emit("contactSelected", selectedContact[0].value);
      const contact = await getContactById(selectedContact[0].value);

      // if the last contact is empty, replace them instead of adding a row
      const numOfContacts = this.contacts.length;
      if (
        numOfContacts > 0 &&
        Object.keys(this.contacts[numOfContacts - 1]).length === 0
      ) {
        this.$set(this.contacts, numOfContacts - 1, { ...contact });
      } else this.contacts.push({ ...contact });
      this.$emit("contactAdded", selectedContact[0].value);
    },
    addContact() {
      this.contacts.push({ email: "", firstname: "", lastname: "" });
    },
    removeContact(index) {
      if (this.contacts.length === 1) return;

      this.contacts.splice(index, 1);
    },
    checkValidity() {
      if (!this.$refs.contactsForm.checkValidity()) {
        this.$refs.contactsForm.requestSubmit();
        return false;
      }
      return true;
    },
    async sendInvite(contactIndex) {
      let contact = this.contacts[contactIndex];
      try {
        await sendEmail(contact, "invite_contact");
        this.notifySuccess("Invite sent.");
      } catch (err) {
        this.notifyError("Error sending invite.");
      }
    },
  },
  async mounted() {
    this.allContactInviteStatus = await getAllContactAccess();
  },
  watch: {
    contacts(newContacts) {
      // Remove empty objects from the array
      newContacts = newContacts.filter(
        (contact) => Object.keys(contact).length !== 0
      );
      newContacts.forEach((contact) => {
        if (Object.keys(contact).length !== 0) {
          contact.inviteStatus = this.getInviteStatus(contact.email);
        }
      });
      this.contactWithInviteStatus = newContacts;
    },
  },
};
</script>
