<template>
  <div>
    <div class="row clearfix">
      <div class="col">
        <div class="d-flex">
          <div>
            <button
              v-if="isDeleteButtonVisible && $auth.user.isAgent"
              type="button"
              class="btn btn-danger custom-delete-button"
              title="Delete Note"
              @click="isConfirmingDelete = true"
              :disabled="isSubscriptionReadOnly() || disableButton"
            >
              <i class="icon-trash"></i>
            </button>
          </div>
          <div class="d-flex-col w-100" style="margin-top: 3px">
            <div class="d-flex flex-wrap font-weight-bold">
              <div class="align-left">
                <p class="title right-border">
                  Date: {{ selectedNotes?.created_on }}
                </p>
              </div>
              <!-- <div class="align-left">
                <p class="title right-border">
                  Time: {{ selectedNotes?.time }}
                </p>
              </div> -->
              <div class="align-left">
                <p class="title right-border">
                  Stage: {{ selectedNotes?.stage_title }}
                </p>
              </div>
              <div class="align-left mr-4">
                <p class="title">
                  By:
                  {{
                    selectedNotes?.created_by_name +
                    " " +
                    selectedNotes?.created_by_surname
                  }}
                </p>
              </div>
              <div class="align-left" :style="{ color: 'gray' }">
                <slot name="icon"></slot>
              </div>
            </div>
            <div
              v-if="alwaysScrollable"
              class="scroll-container mt-4 py-2 px-2"
            >
              <div v-html="selectedNotes?.body"></div>
            </div>
            <div v-else class="scrollable-container pr-lg-5 px-2">
              <div v-html="selectedNotes?.body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionConfirmModal
      v-if="isConfirmingDelete"
      @close="isConfirmingDelete = false"
      @submit="
        () => {
          $emit('delete', selectedNotes);
          isConfirmingDelete = false;
        }
      "
      :disableSubmitButton="disableButton"
    />
  </div>
</template>
<script>
import ActionConfirmModal from "@/components/ui/Modals/ActionConfirmModal.vue";

export default {
  name: "NotesTemplate",
  components: { ActionConfirmModal },
  props: {
    selectedNotes: Object,
    isDeleteButtonVisible: {
      type: Boolean,
      default: false,
    },
    alwaysScrollable: {
      type: Boolean,
      default: true,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isConfirmingDelete: false,
    };
  },
};
</script>
<style scoped>
.title {
  color: #5e5e5e;
  padding: 0 6px;
  margin-bottom: 0;
  line-height: 1.2rem;
  text-wrap: nowrap;
}
.right-border {
  border-right: 1px solid #5e5e5e;
}
.custom-delete-button {
  padding: 0.1rem 0.3rem;
  height: 1.4rem;
  font-size: 0.8rem;
  margin: 0 0 0 0.5rem;
}
.scroll-container {
  color: #5e5e5e;
  overflow-y: auto; /* Enable vertical scrollbar */
  max-height: 13rem;
  min-height: 13rem;
}
.scrollable-container {
  margin-top: 19px;
  max-height: 13rem;
  overflow-y: auto;
}
</style>
