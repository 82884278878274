<template>
  <Modal
    :title="title"
    :show-close-button="false"
    :maxWidth="650"
    :alignTitleLeft="true"
    @submit="onSaveScope"
    @close="$emit('close')"
    close-text="Close"
    submit-text="Save"
    :disableSubmitButton="disableButton"
  >
    <div class="container">
      <div class="d-flex-col">
        <div class="row clearfix">
          <div class="col-md-6 col-5"></div>
          <div class="col-md-6 col-7">
            <table>
              <tr>
                <th></th>
                <th>Notify</th>
                <th>Visible</th>
              </tr>
              <tr>
                <td>Client</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isClientNotify"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isClientVisible"
                      :disabled="isClientVisibleDisabled"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Internal team</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isInternalTeamNotify"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked=""
                      v-model="isInternalTeamVisible"
                      :disabled="isInternalTeamVisibleDisabled"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="form-group">
          <summernote-text-editor
            v-model="editorText"
            :placeholder="placeholder"
            ref="summernoteTextEditor"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import SummernoteTextEditor from "../SummernoteTextEditor.vue";
import Modal from "@/components/ui/Modals/Modal.vue";
export default {
  components: {
    SummernoteTextEditor,
    Modal,
  },
  name: "ProjectScopeEditorModal",
  props: {
    scope: {
      type: Object,
      default: undefined,
    },
    timeline: {
      type: Object,
      default: null,
    },
    title: String,
    action: String,
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placeholder: "Add text here",
      isInternalTeamNotify: this.scope?.notify_agency || false,
      isInternalTeamVisible: this.scope?.visible_to_agency || false,
      isClientNotify: this.scope?.notify_client || false,
      isClientVisible: this.scope?.visible_to_client || false,
      editorText: this.scope?.body || "",
    };
  },
  computed: {
    isClientVisibleDisabled() {
      return this.isClientNotify;
    },
    isInternalTeamVisibleDisabled() {
      return this.isInternalTeamNotify;
    },
  },
  methods: {
    onEditorChange(scopeText) {
      this.editorText = scopeText;
    },
    onSaveScope() {
      const imgTagRegex = /<img[^>]+>/g;
      // Replace <img> tags with modified version
      const modifiedEditorText = this.editorText.replace(
        imgTagRegex,
        (imgTag) => {
          // Add style attribute with width: 100% to <img> tag
          const modifiedImgTag = imgTag.replace(
            /<img /,
            '<img style="max-width: 100%;" '
          );
          return modifiedImgTag;
        }
      );
      const payload = {
        body: modifiedEditorText,
        visible: {
          client: this.isClientVisible,
          agency: this.isInternalTeamVisible,
        },
        notify: {
          client: this.isClientNotify,
          agency: this.isInternalTeamNotify,
        },
      };
      this.$emit("save", {
        payload,
        action: this.action,
        id: this.scope?.id,
        project_id: this.scope?.project_id,
      });
    },
  },
  watch: {
    isClientNotify() {
      if (this.isClientNotify === true) {
        this.isClientVisible = true;
      }
    },
    isInternalTeamNotify() {
      if (this.isInternalTeamNotify === true) {
        this.isInternalTeamVisible = true;
      }
    },
  },
};
</script>
<style>
.custom-icon-color {
  color: gray;
}
table {
  width: 100%;
}
th,
td {
  padding: 2px;
  text-align: left;
}
.container {
  color: #5d5d5d;
  font-family: Montserrat;
}
</style>
