var render = function render(){var _vm=this,_c=_vm._self._c;return _c('wrapper-page',[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('h2',[_vm._v("Team")])]),_c('div',{staticClass:"body"},[_c('form',{ref:"usersForm",on:{"submit":function($event){$event.preventDefault();return _vm.saveDetails.apply(null, arguments)}}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',[_vm._l((_vm.userFields),function(field,index){return _c('th',{key:field.name,staticClass:"text-center",class:index > 2 ? `text-wrap` : index < 2 ? `w200` : `w350`},[_vm._v(" "+_vm._s(field.label)+" ")])}),(_vm.users.length > 1)?_c('th'):_vm._e()],2)]),_c('tbody',_vm._l((_vm.users),function(user,i){return _c('tr',{key:`user-${i}`},[_vm._l((_vm.userFields),function(field){return _c('td',{key:`${i}-${field.name}`},[(
                          field.name === 'name' ||
                          field.name === 'email' ||
                          field.name === 'surname'
                        )?_c('form-input-group',{staticClass:"flex-grow-1",class:{
                          readonly: field.name === 'email' && !!user.id,
                        },attrs:{"field":field,"showLabel":false,"readonly":field.name === 'email' && !!user.id},model:{value:(user[field.name]),callback:function ($$v) {_vm.$set(user, field.name, $$v)},expression:"user[field.name]"}}):_vm._e(),(
                          field.name !== 'name' &&
                          field.name !== 'email' &&
                          field.name !== 'surname'
                        )?_c('div',{staticClass:"fancy-checkbox text-center ml-2 mr-0"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(user[field.name]),expression:"user[field.name]"}],attrs:{"type":"checkbox","id":`input-${field.name}`,"name":field.name,"aria-label":field.name},domProps:{"checked":Array.isArray(user[field.name])?_vm._i(user[field.name],null)>-1:(user[field.name])},on:{"change":[function($event){var $$a=user[field.name],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(user, field.name, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(user, field.name, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(user, field.name, $$c)}},function($event){return _vm.permissionUpdate(user, field.name)}]}}),_c('span',[_vm._v(" ")])])]):_vm._e()],1)}),(_vm.users.length > 1)?_c('td',[(!user.owner && _vm.$auth.user.email !== user.email)?_c('button',{staticClass:"btn-sm btn btn-danger",attrs:{"type":"button","title":"Remove User","disabled":_vm.disableButton},on:{"click":function($event){return _vm.promptRemoveUser(i)}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Remove User")]),_c('i',{staticClass:"icon-trash"})]):_vm._e()]):_vm._e()],2)}),0)])])]),_c('div',{staticClass:"pl-3 mt-3"},[_c('button',{staticClass:"btn btn-primary",class:{ disabled: !_vm.canAddUsers },attrs:{"type":"button","title":"Add User","disabled":_vm.disableButton},on:{"click":_vm.addUser}},[_c('i',{staticClass:"fa fa-plus"}),_c('span',[_vm._v(" Add User")])]),_vm._v("     "),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.disableButton},on:{"click":_vm.submitForm}},[_vm._v(" Save Changes ")])])])])])]),(_vm.isRemovingUserIndex !== false && _vm.isRemovingUserIndex >= 0)?_c('ActionConfirmModal',{on:{"close":function($event){_vm.isRemovingUserIndex = false},"submit":_vm.removeUser}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }