<script>
import { getTrackLinkById } from "../apis/tracklinks";

export default {
  name: "TrackableLinkPage",
  data() {
    return {
      hasTenantInfo: false,
    };
  },
  async created() {
    this.tenantInfo = await this.getTenantInfo();
    this.hasTenantInfo = true;
  },
  watch: {
    hasTenantInfo: async function (new_val) {
      // Ensure the tenant info is loaded since this page can be accessed directly before the part that fetches the tenant id in 'main.js' has finished.
      if (!new_val) return;

      try {
        const link = await getTrackLinkById(
          this.$route.params.linkId,
          this.tenantInfo
        );
        if (!link) {
          console.log("fetched bad link", link);
          throw new Error("Link not loaded");
        }
        window.location = link;
      } catch (err) {
        // Redirect to main page
        console.error(err);
        window.location = window.location.origin;
      }
    },
  },
};
</script>
