var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"timeline-item"},[(_vm.preTitle)?_c('div',{staticClass:"timeline-info"},[_c('span',[_vm._v(_vm._s(_vm.preTitle))])]):_vm._e(),_c('div',{class:_vm.markerClasses},[(_vm.displayFlagIcon)?_c('i',{staticClass:"fa fa-flag fa-lg text-cyan"}):_vm._e(),(_vm.displayCheckIcon)?_c('div',{staticClass:"icon text-cyan text-large"},[_c('i',{staticClass:"fa fa-check-circle"})]):_vm._e()]),_c('div',{staticClass:"timeline-content"},[_c('h3',{staticClass:"timeline-title"},[_c('div',[_vm._v(" "+_vm._s(_vm.model.title)+" "),_c('stage-menu',{attrs:{"items":_vm.makeMenu([
              !_vm.model.status &&
                !_vm.root.is_linear &&
                _vm.model.type === 'Stage' &&
                _vm.isAgency &&
                _vm.menuItems.StartStage,
              !_vm.model?.revisionsWithoutPreview?.length &&
                _vm.model.type === 'Stage' &&
                _vm.model.status === 'in_progress' &&
                _vm.isAgency &&
                _vm.menuItems.SendMessage,
              _vm.model.type === 'Stage' && _vm.isAgency && _vm.menuItems.AddNote,
              _vm.canUndoLastAction && _vm.menuItems.UndoLastAction,
              _vm.model.type === 'Revision' &&
                _vm.model.status === 'in_progress' &&
                _vm.isAgency &&
                _vm.menuItems.ClientApproved,
            ])},on:{"clicked":(item) => {
              _vm.onMenuClick(item, _vm.model);
            }}}),(_vm.model.type === 'Stage' && _vm.isNotesAvailable)?_c('span',[_c('div',{staticClass:"d-inline-block pl-1"},[_c('a',{style:({ color: '#bfbfbf' }),attrs:{"href":"#","data-toggle":"modal"},on:{"click":function($event){return _vm.onNotesIconClicked(_vm.model)}}},[_c('img',{staticStyle:{"margin-top":"-1px"},attrs:{"src":"/assets/icons/note.svg","height":"20px","width":"20px"}})])])]):_vm._e(),(
            _vm.model.type !== 'Stage' &&
            _vm.model.status &&
            _vm.model.status !== 'rejected'
          )?_c('span',{class:_vm.cellClass_ProjectStatus(_vm.project)},[_c('span',[_vm._v(_vm._s(_vm.cellText_ProjectStatus(this.project)))])]):_vm._e()],1),(_vm.isExpandable)?_c('button',{staticClass:"btnExpand",on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},[(_vm.isExpanded)?_c('i',{staticClass:"fa fa-angle-up"}):_c('i',{staticClass:"fa fa-angle-down"})]):_vm._e()]),(this.model.type === 'Revision')?_c('h6',{staticClass:"small text-black-50",staticStyle:{"position":"relative"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.subtitleText())}}),(_vm.isQCChecklistAvailable)?[_c('span',[_vm._v(_vm._s(_vm.getSubtitleWithQCCheckedBy(_vm.model))+" ")])]:_vm._e(),(
          _vm.model?.data?.review_link &&
          !_vm.files?.length &&
          this.model.status !== 'awaiting_feedback'
        )?[_c('span',[_vm._v(" | ")]),_c('a',{staticClass:"shared-link text-black-50",attrs:{"href":_vm.reviewLink(_vm.model),"target":"_blank"}},[_vm._v(" Shared link ")])]:_vm._e()],2):_vm._e(),(_vm.$auth.user.isAgent)?_c('date-time-picker',{staticClass:"stage-date-change",attrs:{"type":"datetime","value":new Date(this.model.started_on),"input-class":"form-date-control","format":"YYYY-MM-DD HH:mm A","input-attr":{
        id: `change-started-date-timeline-item-${_vm.model.id}`,
        ref: `change-started-date-timeline-item-${_vm.model.id}`,
        name: `change-started-date-timeline-item-${_vm.model.id}`,
      },"disabled-date":(date) => {
          const today = new Date();
          return date > today;
        }},on:{"input":(v) => _vm.onDateChange(v, 'started_on')}}):_vm._e(),(_vm.$auth.user.isAgent)?_c('date-time-picker',{staticClass:"stage-date-change",attrs:{"type":"datetime","value":new Date(this.model.completed_on),"input-class":"form-date-control","format":"YYYY-MM-DD HH:mm A","input-attr":{
        id: `change-completed-date-timeline-item-${_vm.model.id}`,
        ref: `change-completed-date-timeline-item-${_vm.model.id}`,
        name: `change-completed-date-timeline-item-${_vm.model.id}`,
      },"disabled-date":(date) => {
          if (this.model.status === 'rejected')
            return date < new Date(this.model.sent_on);

          const today = new Date();
          return date > today;
        }},on:{"input":(v) => _vm.onDateChange(v, `${this.model.status}_on`)}}):_vm._e(),(_vm.$auth.user.isAgent)?_c('date-time-picker',{staticClass:"stage-date-change",attrs:{"type":"datetime","value":new Date(this.model.sent_on),"input-class":"form-date-control","format":"YYYY-MM-DD HH:mm A","input-attr":{
        id: `change-shared-date-timeline-item-${_vm.model.id}`,
        ref: `change-shared-date-timeline-item-${_vm.model.id}`,
        name: `change-shared-date-timeline-item-${_vm.model.id}`,
      },"disabled-date":(date) => {
          const today = new Date();
          const started = new Date(this.model.started_on);
          return date < started || date > today;
        }},on:{"input":(v) => _vm.onDateChange(v, 'sent_on')}}):_vm._e(),(this.model.type === 'Stage')?_c('stage-subtitle-text',{attrs:{"stage":_vm.model,"workflow":_vm.root}}):_vm._e(),(
        _vm.isAgency &&
        _vm.model.type === 'Revision' &&
        _vm.isOpen &&
        (_vm.model.status !== 'awaiting_feedback' ||
          _vm.model.id === _vm.revisionIdForReminder)
      )?_c('revision-agency-timeline',{attrs:{"shouldShowActionButtons":_vm.model.id === _vm.revisionIdForReminder,"skipComposeButton":_vm.model.id === _vm.revisionIdForReminder,"project":_vm.project,"revision":_vm.model,"workflow":_vm.root,"files":_vm.files,"previousRevisionReviewLink":_vm.previousRevisionReviewLink,"disableButton":_vm.disableButton},on:{"addedFiles":_vm.addedFiles,"removeFile":_vm.removeFile,"revision_stage_approve_click":function($event){return _vm.$emit('revision_stage_approve_click', $event)},"revision_send_preview_click":function($event){return _vm.$emit('revision_send_preview_click', $event)}}}):_vm._e(),(!_vm.isAgency && _vm.model.type === 'Revision' && _vm.isOpen)?_c('revision-client-timeline',{attrs:{"project":this.project,"revision":_vm.model,"workflow":_vm.root,"files":_vm.files,"disableButton":_vm.disableButton},on:{"revision_send_message_click":function($event){return _vm.$emit('revision_send_message_click', $event)},"revision_client_approve_click":function($event){return _vm.$emit('revision_client_approve_click', $event)},"revision_client_reject_click":function($event){return _vm.$emit('revision_client_reject_click', $event)}}}):_vm._e(),(
        _vm.isAgency &&
        _vm.model.type === 'Revision' &&
        _vm.isOpen &&
        _vm.model.status === 'awaiting_feedback' &&
        _vm.model.id !== _vm.revisionIdForReminder
      )?_c('timeline-revision-agency-awaiting-feedback',{attrs:{"project":this.project,"workflow":_vm.root,"revision":_vm.model,"files":_vm.files,"disableButton":_vm.disableButton},on:{"revision_reject_revision_click":function($event){return _vm.$emit('revision_reject_revision_click', $event)},"revision_stage_approve_click":function($event){return _vm.$emit('revision_stage_approve_click', $event)},"open_revision_for_reminder":function($event){_vm.revisionIdForReminder = $event},"removeFile":(i) => _vm.removeFile(i)}}):_vm._e(),(!_vm.isOpen && _vm.isExpanded)?_c('div',{staticClass:"col-lg-8 col-sm-12 pl-0 mt-4"},[_c('revision-files',{attrs:{"files":_vm.files,"editable":false}})],1):_vm._e()],1),(_vm.isAgency && _vm.isSendMessageModalOpen)?_c('SendMessageModal',{attrs:{"model":_vm.model,"project":_vm.project,"workflow":_vm.root,"disableButton":_vm.disableButton},on:{"send":_vm.sendMessage,"close":function($event){_vm.isSendMessageModalOpen = false}}}):_vm._e(),(_vm.isUndoLastActionConfirmModalOpen)?_c('UndoLastActionConfirmModal',{attrs:{"disableSubmitButton":_vm.isGoingBackStage || _vm.disableButton},on:{"submit":_vm.undoLastAction,"close":function($event){_vm.isUndoLastActionConfirmModalOpen = false}}}):_vm._e(),(_vm.isShowNotesModal)?_c('notes-modal',{attrs:{"stageNotes":_vm.selectedStageNotes,"disableButton":_vm.disableButton},on:{"close":function($event){_vm.isShowNotesModal = false}}}):_vm._e(),(_vm.isShowNotesEditorModal)?_c('NotesEditorModal',{attrs:{"title":"Add note","timeline":_vm.parent,"action":"add","selectedStage":_vm.model,"disableButton":_vm.disableOnSaveNote},on:{"close":function($event){_vm.isShowNotesEditorModal = false},"save":_vm.onSaveNote}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }