import { render, staticRenderFns } from "./ProjectScopeTab.vue?vue&type=template&id=0b61e0e0"
import script from "./ProjectScopeTab.vue?vue&type=script&lang=js"
export * from "./ProjectScopeTab.vue?vue&type=script&lang=js"
import style0 from "./ProjectScopeTab.vue?vue&type=style&index=0&id=0b61e0e0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports