<template>
  <div class="d-flex flex-column" style="padding: 10px; max-height: 90vh">
    <email-template-preview
      :value="deliverableTemplate"
      :project="project"
      :language="language"
    />

    <div class="col-12 mt-4 d-flex justify-content-end">
      <button
        :disabled="disableButton"
        @click="close"
        class="btn btn-danger"
        type="button"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary ml-3"
        @click="sendDeliverable"
        :disabled="disableButton"
      >
        Save & Send
      </button>
    </div>
  </div>
</template>

<script>
import EmailTemplatePreview from "../ui/EmailTemplates/EmailTemplatePreview.vue";

export default {
  name: "ProjectDeliverablePreviewModal",
  components: { EmailTemplatePreview },
  props: {
    deliverable: {
      type: Object,
    },
    template: {
      type: String,
    },
    project: {
      type: Object,
    },
    language: {
      type: String,
      default: "en",
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    deliverableTemplate() {
      // Changes to these placeholders should also be reflected in Crud > projects-deliverables-send-one\index.ts > replaceEmailPlaceholders()
      return this.template
        .replace(
          /(ftp|sftp|http|https):\/\/\[deliverable( |%20)link\]/g,
          this.deliverable.link ?? `${this.$route.path}#deliverables`
        )
        .replace(/\[deliverable( |%20)name\]/g, this.deliverable.title ?? "")
        .replace(/\[deliverable( |%20)notes\]/g, this.deliverable.body ?? "");
    },
  },
  methods: {
    close(state = null) {
      this.$modal.hide("project-deliverables-preview-modal", state);
    },
    async sendDeliverable() {
      this.close({ value: "send" });
    },
  },
};
</script>
